import React from 'react';
import BaseField from './BaseField'
import { connectField } from './Connect'
import { DatePicker, Form } from 'antd';
import moment from 'moment'
class DatePickerField extends BaseField {

  onChangeDate = (date) => {
    const e = {
      target: {
        name: this.props.name,
        value: date? moment(date).toISOString(): null
      }
    }
    const {field} = this.props
    if(typeof this.props.onChange === "function"){
      this.props.onChange(date)
    }
    return field.onChange(e)
  }

  getValue = () => {
    const { field } = this.props;
    return moment(field.value).isValid() ? moment(field.value) : null;
  }

  onBlur = (e) => {
    const { field } = this.props
    if (typeof this.props.onBlur === "function") {
      this.props.onBlur(e)
    }
    return field.onBlur(e)
  }

  render() {
    let { meta, label, ItemProps, ...otherProps } = this.props;
    return (

      <Form.Item
        label={label}
        validateStatus={this.getError() ? 'error' : 'success'}
        help={this.getError()}
        {...ItemProps}
      >
        <DatePicker
          {...otherProps}
          onChange={this.onChangeDate}
          onBlur={this.onBlur}
          value={this.getValue()}
        />
      </Form.Item>
    )
  }
}
export default connectField(DatePickerField)