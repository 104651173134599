import React from 'react';
import BaseField from './BaseField'
import {connectField} from './Connect'
import { Input, Form } from 'antd';

class InputField extends BaseField {

  render() {
    let {meta, label,ItemProps, ...otherProps} = this.props
    // console.log("render.....", this.props.name)
    // console.log(meta)
    // console.log("----------------------")

    return(

      <Form.Item
      label={label}
      validateStatus={this.getError() ? 'error': 'success'}
      help={this.getError()}
      {...ItemProps}
      >
        <Input
        {...otherProps}
        onChange={this.onChange}
        onBlur={this.onBlur}
        value={this.getValue()}
        />
      </Form.Item>
    )
  }
}
export default connectField(InputField)