import React from 'react';
import {Provider} from './Connect'
import { Form } from 'antd';

class FormComponent extends React.PureComponent {
  constructor(props){
    super(props)
  }
  render() {
    const {form, ...otherProps} = this.props
    return <Form {...otherProps}/>
  }
}
export default Provider(FormComponent)