import React from 'react';
import _ from 'lodash'
import isEqual from "react-fast-compare";
class BaseField extends React.Component {
  constructor(props){
    super(props)
  }

  shouldComponentUpdate(nextProps, nextState){
    let {meta : oMeta, field: oField, ...oProps} = this.props
    let {meta : nMeta, field: nField, ...nProps} = nextProps
    return !isEqual(oMeta, nMeta) || !isEqual(oProps, nProps) || !isEqual(JSON.stringify(oField), JSON.stringify(nField))
  }

  getError = () =>{
    let {meta} = this.props

    return meta.touched && meta.error ? meta.error: undefined
  }

  onChange = (e) => {
    const {field} = this.props
    if(typeof this.props.onChange === "function"){
      this.props.onChange(e)
    }
    return field.onChange(e)
  }

  onBlur = (e) => {
    const {field} = this.props

    if(typeof this.props.onBlur === "function"){
      this.props.onBlur(e)
    }
    return field.onBlur(e)
  }
  getValue = () => {
    const {field} = this.props;
    return field.value;
  }
  render() {
    throw new Error("Need render method in Field")
  }
}
export default BaseField