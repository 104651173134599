import React from 'react';
import BaseField from './BaseField'
import { connectField } from './Connect'
import { Select, Form } from 'antd';
const { Option } = Select
class SelectField extends BaseField {
  onChangeSelect = (value) => {
    const e = {
      target: {
        name: this.props.name,
        value: value
      }
    }
    this.onChange(e);
  }

  render() {
    let { meta, label, ItemProps, children, ...otherProps } = this.props;
    return (

      <Form.Item
        label={label}
        validateStatus={this.getError() ? 'error' : 'success'}
        help={this.getError()}
        {...ItemProps}
      >
        <Select
          {...otherProps}
          value={this.getValue()}
          onChange={this.onChangeSelect}
        >
          {this.props.children}
        </Select>
      </Form.Item>
    )
  }
}

export default connectField(SelectField)

export {
  Option
}