import React from 'react';
import BaseField from './BaseField'
import {connectField} from './Connect'
import { Switch, Form } from 'antd';
class Field extends BaseField {
  onChangeSwitch = (checked) => {
    const e = {
      target: {
        name: this.props.name,
        value: checked
      }
    }
    this.onChange(e)
  }

  render() {
    let {meta, label,ItemProps, ...otherProps} = this.props
    const checked = this.getValue()
    return(
      <Form.Item
      >
        <Switch
        {...otherProps}
        onChange={this.onChangeSwitch}
        defaultChecked={checked}
        />
      </Form.Item>
    )
  }
}
export default connectField(Field)