import React from 'react';
import BaseField from './BaseField'
import { connectField } from './Connect'
import { Radio, Form } from 'antd';
class RadioGroupField extends BaseField {
    render() {
        let { meta, label, ItemProps, children, ...otherProps } = this.props
        return (

            <Form.Item
                label={label}
                validateStatus={this.getError() ? 'error' : 'success'}
                help={this.getError()}
                {...ItemProps}
            >
                <Radio.Group
                    {...otherProps}
                    value={this.getValue()}
                    onChange={this.onChange}
                >
                    {children}
                </Radio.Group>
            </Form.Item>
        )
    }
}

export default connectField(RadioGroupField)

export {
    Radio
}