import React from 'react';
import BaseField from './BaseField'
import {connectField} from './Connect'
import { Input, Form } from 'antd';
const { TextArea } = Input;

class InputField extends BaseField {

  render() {
    let {meta, label,ItemProps, ...otherProps} = this.props
    return(

      <Form.Item
      label={label}
      validateStatus={this.getError() ? 'error': 'success'}
      help={this.getError()}

      {...ItemProps}
      >
        <TextArea
        {...otherProps}
        onChange={this.onChange}
        onBlur={this.onBlur}
        value={this.getValue()}
        />
      </Form.Item>
    )
  }
}
export default connectField(InputField)