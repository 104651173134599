import React from 'react';
import BaseField from './BaseField'
import {connectField} from './Connect'
import { Checkbox, Form } from 'antd';
class CheckboxField extends BaseField {
  onChangeCheckbox = (event) => {
    const e = {
      target: {
        name: this.props.name,
        value: event.target.checked
      }
    }
    this.onChange(e)
  }

  render() {
    let {form, label,ItemProps, ...otherProps} = this.props
    const checked = this.getValue()
    return(
      <Form.Item
       {...ItemProps}
      >
        <Checkbox
        {...otherProps}
        onChange={this.onChangeCheckbox}
        defaultChecked={checked}
        />
      </Form.Item>
    )
  }
}
export default connectField(CheckboxField)