import React from 'react';
import { useFormik, connect, useField, useFormikContext, Formik } from 'formik';

// Create empty context
const FormikContext = React.createContext({});

const SetHelpersCallback = (props) => {
  const formikStateAndHelpers = useFormikContext()

  if(typeof props.formHelpers === "function"){
    props.formHelpers(formikStateAndHelpers)
  }
  return "";
}
// Place all of what's returned by useFormik onto context
const Provider = (Component) => ({ children, ...props }) => {
  props.initialValues = props.initialValues || {}
  return (
      <Formik {...props}>
        {propsFormilk => (
          <Component onSubmit={propsFormilk.handleSubmit}>
            <SetHelpersCallback {...props} />
            {children}
          </Component>
        )}
      </Formik>
  );
};

const connectField = (Component) => ({...props}) => {
  const [field, meta] = useField(props)

  return (
      <Component {...props} meta={meta} field={field} />
  );
};

export {
  Provider,
  connectField
}